import { Message } from "./proto/message_pb";
import { Command } from "./proto/command_pb";
import { Player } from './player';
import { Map as GameMap } from './map';
import { Camera } from "./camera";
import { Data } from './proto/data_pb';
import { Info } from './proto/info_pb';
import { GameInfo } from './gameInfo';
import { Control } from './control';
var Point = /** @class */ (function () {
    function Point(x, y) {
        if (x === void 0) { x = 0; }
        if (y === void 0) { y = 0; }
        this.x = x;
        this.y = y;
    }
    return Point;
}());
export { Point };
;
export var Direction;
(function (Direction) {
    Direction[Direction["Stop"] = 0] = "Stop";
    Direction[Direction["Up"] = 1] = "Up";
    Direction[Direction["Down"] = 2] = "Down";
    Direction[Direction["Left"] = 3] = "Left";
    Direction[Direction["Right"] = 4] = "Right";
    Direction[Direction["None"] = 5] = "None";
})(Direction || (Direction = {}));
var Game = /** @class */ (function () {
    function Game(canvas, communication) {
        var _this = this;
        this.communication = communication;
        this.canvasWidth = 0; // width of the canvas
        this.canvasHeight = 0; // height of the canvas
        this.lastTime = 0;
        // private gameTime: number = 0;
        this.playDirection = Direction.Stop;
        this.previousPlayDirection = Direction.Stop;
        this.playerId = 0;
        this.players = new Map();
        this.infoEl = new GameInfo();
        this.serverDelta = 0.0;
        this.playerNearValue = 1500;
        this.requestAnimationFrame = function (callback) {
            return window.requestAnimationFrame(callback);
        };
        this.resizeCanvasToDisplaySize = function (canvas) {
            // look up the size the canvas is being displayed
            var width = canvas.clientWidth;
            var height = canvas.clientHeight;
            // If it's resolution does not match change it
            if (canvas.width !== width || canvas.height !== height) {
                canvas.width = width;
                canvas.height = height;
            }
        };
        this.ctx = canvas.getContext('2d');
        this.resizeCanvasToDisplaySize(this.ctx.canvas);
        this.control = new Control(this.ctx.canvas.width, this.ctx.canvas.height);
        var width = 5000;
        var height = 3000;
        this.map = new GameMap(width, height);
        this.map.generate();
        // Set the right viewport size for the camera
        var vWidth = Math.min(width, canvas.width);
        var vHeight = Math.min(height, canvas.height);
        // Setup the camera
        this.camera = new Camera(0, 0, vWidth, vHeight, width, height);
        // Setup the control
        var that = this;
        window.addEventListener('keydown', function (event) {
            var playDirection = that.control.getDirectionBykeyCode(event.keyCode);
            if (playDirection !== Direction.None) {
                that.playDirection = playDirection;
            }
        }, false);
        var elemLeft = canvas.offsetLeft, elemTop = canvas.offsetTop;
        canvas.addEventListener('click', function (event) {
            var x = event.pageX - elemLeft, y = event.pageY - elemTop;
            var playDirection = that.control.getDirectionByPoint(new Point(x, y));
            if (playDirection !== Direction.None) {
                that.playDirection = playDirection;
            }
        }, false);
        // Processing message
        this.communication.onMesssage(function (msg) {
            if (msg.getType() === Message.Type.JOINED && !_this.players.has(msg.getPlayerid())) {
                var player = new Player(msg.getPlayerid(), 50, 50);
                player.setDirection(Direction.None);
                _this.players.set(msg.getPlayerid(), player);
                _this.playerId = msg.getPlayerid();
                _this.camera.follow(player, vWidth / 2, vHeight / 2);
            }
            else if (msg.getType() === Message.Type.INFO) {
                var info = Info.deserializeBinary(msg.getData_asU8());
                _this.infoEl.setInfo(info);
            }
            else if (msg.getType() === Message.Type.QUIT) {
                if (_this.players.has(msg.getPlayerid())) {
                    _this.players.delete(msg.getPlayerid());
                }
            }
            else if (msg.getType() === Message.Type.DATA) {
                var data = Data.deserializeBinary(msg.getData_asU8());
                // set server delta need to interpolation
                _this.serverDelta = data.getDelta();
                var player = void 0;
                if (_this.players.has(msg.getPlayerid())) {
                    player = _this.players.get(msg.getPlayerid());
                    player.setColor(data.getColor());
                }
                else {
                    player = new Player(msg.getPlayerid(), data.getX(), data.getY(), 200, 50, data.getColor());
                    player.setDirection(Direction.None);
                    _this.players.set(msg.getPlayerid(), player);
                }
                player.applyData(data);
                // if (msg.getPlayerid() === this.playerId) {
                //   player.applyData(data);
                // } else {
                //   player.addData(data);
                // }
                // // reconciliation
                // if (msg.getPlayerid() === this.playerId) {
                //   player.reconciliation(data);
                // } else {
                //   // ineed to interpolation
                //   player.addData(data);
                // }
            }
        });
    }
    Game.prototype.start = function () {
        var _this = this;
        var now = Date.now();
        var dt = (now - this.lastTime) / 1000.0;
        this.update(dt);
        this.render();
        this.lastTime = now;
        this.requestAnimationFrame(function () { return _this.start(); });
    };
    Game.prototype.update = function (dt) {
        // this.gameTime += dt;
        this.handleInput(dt);
        this.updateEntities(dt);
    };
    Game.prototype.handleInput = function (dt) {
        // pass if the same direction
        if (this.playDirection === Direction.None) {
            return;
        }
        if (this.players.has(this.playerId)) {
            this.players.get(this.playerId).setDirection(this.playDirection);
        }
        // send to server
        var message = new Message();
        message.setType(Message.Type.COMMAND);
        var direction = new Command();
        var xv = 0;
        var yv = 0;
        switch (this.playDirection) {
            case Direction.Down:
                yv = 1;
                xv = 0;
                break;
            case Direction.Up:
                yv = -1;
                xv = 0;
                break;
            case Direction.Left:
                yv = 0;
                xv = -1;
                break;
            case Direction.Right:
                yv = 0;
                xv = 1;
                break;
        }
        if (yv != 0 || xv != 0) {
            this.players.get(this.playerId).setVelocity(yv, xv);
        }
        direction.setTime(new Date().getTime());
        direction.setXv(xv);
        direction.setYv(yv);
        message.setData(direction.serializeBinary());
        this.communication.sendMessage(message);
        // end
        if (this.players.has(this.playerId)) {
            var player = this.players.get(this.playerId);
            player.setVelocity(yv, xv);
            player.update(dt, 5000, 3000);
        }
        // if (this.players.has(this.playerId)) {
        //   const player = this.players.get(this.playerId)!
        //   player.addCommand(direction);
        // }
        // this.previousPlayDirection = this.playDirection;
        this.playDirection = Direction.None;
    };
    Game.prototype.updateEntities = function (dt) {
        var _this = this;
        // Update the player sprite animation
        this.players.forEach(function (player) {
            // player.interpolate(this.serverDelta)
            player.update(dt, 5000, 3000);
            // Collision check
            _this.players.forEach(function (otherPlayer) {
                if (player.id != otherPlayer.id
                    && player.isNear(otherPlayer, _this.playerNearValue)
                    && player.overlaps(otherPlayer)) {
                    player.revertDirection();
                    otherPlayer.revertDirection();
                }
            });
        });
        this.camera.update();
    };
    Game.prototype.render = function () {
        var _this = this;
        this.canvasWidth = this.ctx.canvas.width;
        this.canvasHeight = this.ctx.canvas.height;
        this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.map.draw(this.ctx, this.camera.xView, this.camera.yView);
        this.infoEl.render(this.ctx, this.canvasWidth, this.canvasHeight);
        this.players.forEach(function (player) {
            player.render(_this.ctx, _this.camera.xView, _this.camera.yView);
        });
    };
    return Game;
}());
export { Game };
