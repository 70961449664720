import { Point, Direction } from './game';
var Control = /** @class */ (function () {
    function Control(width, height) {
        this.width = width;
        this.height = height;
    }
    /**
     * getDirectionByKeywordCode
     */
    Control.prototype.getDirectionBykeyCode = function (keyCode) {
        var playDirection = Direction.None;
        switch (keyCode) {
            case 37: // Left
                playDirection = Direction.Left;
                break;
            case 38: // Up
                playDirection = Direction.Up;
                break;
            case 39: // Right
                playDirection = Direction.Right;
                break;
            case 40: // Down
                playDirection = Direction.Down;
                break;
        }
        return playDirection;
    };
    /**
     * -------
     * |\   /|
     * | \ / |
     * | / \ |
     * |/   \|
     * -------
     */
    Control.prototype.getDirectionByPoint = function (p) {
        // center
        var cx = this.width / 2;
        var cy = this.height / 2;
        var center = new Point(cx, cy);
        // - x
        // | y
        // [x, y]
        // top
        var topLeft = new Point(0, 0);
        var topRight = new Point(this.width, 0);
        // botton
        var bottonLeft = new Point(0, this.height);
        var bottonRight = new Point(this.width, this.height);
        // left
        var leftTop = new Point(this.width, 0);
        var leftBotton = new Point(this.width, this.height);
        // right
        var rightTop = new Point(0, 0);
        var rightBotton = new Point(0, this.height);
        // Up 
        if (this.pointInTriangle(p, topLeft, topRight, center)) {
            return Direction.Up;
            // Down
        }
        else if (this.pointInTriangle(p, center, bottonRight, bottonLeft)) {
            return Direction.Down;
            // Right
        }
        else if (this.pointInTriangle(p, leftTop, center, leftBotton)) {
            return Direction.Right;
            // Left
        }
        else if (this.pointInTriangle(p, rightTop, rightBotton, center)) {
            return Direction.Left;
        }
        return Direction.None;
    };
    Control.prototype.pointInTriangle = function (pt, v1, v2, v3) {
        var d1, d2, d3;
        var has_neg, has_pos;
        d1 = this.sign(pt, v1, v2);
        d2 = this.sign(pt, v2, v3);
        d3 = this.sign(pt, v3, v1);
        has_neg = (d1 < 0) || (d2 < 0) || (d3 < 0);
        has_pos = (d1 > 0) || (d2 > 0) || (d3 > 0);
        return !(has_neg && has_pos);
    };
    Control.prototype.sign = function (p1, p2, p3) {
        return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);
    };
    return Control;
}());
export { Control };
