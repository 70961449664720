import './style/normalize.css';
import './style/style.css';
import { Message } from "./proto/message_pb";
import { Game } from './game';
import { Сommunication } from './communication';
var App = /** @class */ (function () {
    function App(canvas) {
        this.canvas = canvas;
        this.communication = new Сommunication();
    }
    App.prototype.start = function () {
        var _this = this;
        new Game(this.canvas, this.communication).start();
        // join to game
        setTimeout(function () {
            var message = new Message();
            message.setType(Message.Type.JOIN);
            _this.communication.sendMessage(message);
        }, 1000);
    };
    return App;
}());
var gameCanvas = document.getElementById('game');
new App(gameCanvas).start();
