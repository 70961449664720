import { Direction } from './game';
var Player = /** @class */ (function () {
    function Player(id, x, y, speed, radius, color) {
        if (x === void 0) { x = 100; }
        if (y === void 0) { y = 100; }
        if (speed === void 0) { speed = 200; }
        if (radius === void 0) { radius = 50; }
        if (color === void 0) { color = '#ffffff00'; }
        this.id = id;
        this.x = x;
        this.y = y;
        this.speed = speed;
        this.radius = radius;
        this.color = color;
        this.vx = 0;
        this.vy = 0;
        this.direction = Direction.Stop;
        this.commands = [];
        this.dataBuffer = [];
    }
    Player.prototype.update = function (dt, worldWidth, worldHeight) {
        // parameter step is the time between frames ( in seconds )
        if (this.vy != 0) {
            this.y = this.y + (dt * this.speed * this.vy);
        }
        if (this.vx != 0) {
            this.x = this.x + (dt * this.speed * this.vx);
        }
        // don't let player leaves the world's boundary
        if (this.y + this.radius > worldHeight || this.y - this.radius < 0) {
            this.vy = (this.y + this.radius) > worldHeight ? -1 : 1;
        }
        if (this.x + this.radius > worldWidth || this.x - this.radius < 0) {
            this.vx = (this.x + this.radius) > worldWidth ? -1 : 1;
        }
    };
    Player.prototype.applyData = function (data) {
        this.setPosition(data.getY(), data.getX());
        this.setVelocity(data.getYv(), data.getXv());
    };
    // reconciliation
    Player.prototype.addCommand = function (command) {
        this.commands.push(command);
    };
    Player.prototype.reconciliation = function (data) {
        // Received the authoritative position of this player.
        // this.applyData(data);
        // this.y = data.getY()
        // this.x = data.getX()
        if (this.commands.length === 0) {
            this.applyData(data);
        }
        else if (data.getTime() !== 0) {
            var j = 0;
            while (j < this.commands.length) {
                var command = this.commands[j];
                if (command.getTime() <= data.getTime()) {
                    // Already processed. Its effect is already taken into account into the world update
                    // we just got, so we can drop it.
                    this.commands.splice(j, 1);
                }
                else {
                    // Not processed by the server yet. Re-apply it.
                    this.applyData(data);
                    j++;
                }
            }
        }
    };
    Player.prototype.interpolate = function (dt) {
        // Compute render timestamp.
        var now = +new Date();
        var render_timestamp = now - (1000 * dt);
        // console.log((dt))
        // if (this.dataBuffer.length >= 2){
        //   console.log(this.dataBuffer[1][0] <= render_timestamp)
        // }
        // Drop older positions.
        while (this.dataBuffer.length >= 2 && this.dataBuffer[1][0] <= render_timestamp) {
            this.dataBuffer.shift();
        }
        // console.log(this.dataBuffer.length);
        // Interpolate between the two surrounding authoritative positions.
        if (this.dataBuffer.length >= 2 && this.dataBuffer[0][0] <= render_timestamp && render_timestamp <= this.dataBuffer[1][0]) {
            var p0 = this.dataBuffer[0][1];
            var p1 = this.dataBuffer[1][1];
            var t0 = this.dataBuffer[0][0];
            var t1 = this.dataBuffer[1][0];
            // const x = p0.getX() + (p1.getX() - p0.getX()) * (render_timestamp - t0) / (t1 - t0);
            // const y = p0.getY() + (p1.getY() - p0.getY()) * (render_timestamp - t0) / (t1 - t0);
            // this.x = this.x + (dt *this.speed * this.vx);
            var x = p0.getX() + (p1.getX() - p0.getX()) * (render_timestamp - t0) / (t1 - t0);
            var y = p0.getY() + (p1.getY() - p0.getY()) * (render_timestamp - t0) / (t1 - t0);
            // this.applyData(this.dataBuffer[0][1])
            //console.log(x, y);
            this.setPosition(y, x);
            this.setVelocity(p0.getYv(), p0.getXv());
        }
    };
    Player.prototype.addData = function (data) {
        var timestamp = +new Date();
        this.dataBuffer.push([timestamp, data]);
    };
    Player.prototype.setColor = function (color) {
        this.color = color;
    };
    Player.prototype.setDirection = function (direction) {
        this.direction = direction;
    };
    Player.prototype.setPosition = function (y, x) {
        this.y = y;
        this.x = x;
    };
    Player.prototype.setVelocity = function (vy, vx) {
        this.vy = vy;
        this.vx = vx;
    };
    Player.prototype.render = function (ctx, xView, yView) {
        ctx.beginPath();
        ctx.arc(this.x - xView, this.y - yView, this.radius, 0, Math.PI * 2, true);
        ctx.closePath();
        // Colors and fills the ball
        ctx.fillStyle = this.color;
        ctx.fill();
    };
    // Determines is a player is near other player.
    Player.prototype.isNear = function (other, playerNearValue) {
        var xdiff = Math.abs(this.x - other.x);
        var ydiff = Math.abs(this.y - other.y);
        var mdiff = Math.max(xdiff, ydiff);
        return mdiff < playerNearValue;
    };
    Player.prototype.overlaps = function (other) {
        var dx = this.x - other.x;
        var dy = this.y - other.y;
        var distance = Math.sqrt(dx * dx + dy * dy);
        return distance < this.radius + other.radius;
    };
    Player.prototype.revertDirection = function () {
        this.setVelocity(-this.vy, -this.vx);
    };
    return Player;
}());
export { Player };
