import { Message } from "./proto/message_pb";
var Сommunication = /** @class */ (function () {
    function Сommunication() {
        var _this = this;
        this.handlers = [];
        this.connected = false;
        this.connect = new WebSocket(this.url("ws"));
        this.setup();
        setInterval(function () {
            if (_this.connect.CLOSED == _this.connect.readyState) {
                _this.connect = new WebSocket(_this.url("ws"));
                _this.setup();
            }
        }, 1000);
    }
    Сommunication.prototype.sendMessage = function (message) {
        if (this.connected) {
            this.connect.send(message.serializeBinary());
        }
    };
    ;
    Сommunication.prototype.onMesssage = function (handler) {
        this.handlers.push(handler);
    };
    Сommunication.prototype.setup = function () {
        var that = this;
        this.connect.onopen = function () {
            this.binaryType = "arraybuffer";
            that.connected = true;
        };
        this.connect.onclose = function () {
            that.connected = false;
        };
        this.connect.onmessage = function (event) {
            var message = Message.deserializeBinary(event.data);
            for (var _i = 0, _a = that.handlers; _i < _a.length; _i++) {
                var handler = _a[_i];
                handler(message);
            }
        };
    };
    Сommunication.prototype.url = function (s) {
        var l = window.location;
        return ((l.protocol === "https:") ? "wss://" : "ws://") + l.hostname + (((l.port != "80") && (l.port != "443")) ? ":" + l.port : "") + l.pathname + s;
    };
    return Сommunication;
}());
export { Сommunication };
